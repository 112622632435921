import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './modules/Home'
import Dictionary from './modules/Dictionary'
import './App.css'
const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='/dict/:q' element={<Dictionary/>}></Route>
    </Routes>
  )
}

export default App