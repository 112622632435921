import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
const isEmpty = require("../functions/functions")
const Dictionary = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    setSearch(params.q.split("+").join(" "));
  }, [params]);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading === true&&search) {
      const fetchData = async () => {
        fetch(`https://samaydictionary.vercel.app/v1?q=${search}`)
          .then((j) => j.json())
          .then((d) => {
            console.log(`https://samaydictionary.vercel.app/v1?q=${search}`);
            setData(d);
            if (d.status === 200) {
              setLoading(false);
            }
          });
      };
      fetchData();
    }
  }, [search]);
  const hanndleWord = (w) => {
    setLoading(true);
    navigate(`/dict/${w}`);
  };
  return (
    <div className="dict">
      <div className="nav">
        <h2
          onClick={() => {
            navigate("/");
          }}
        >
          Dictionary
        </h2>
        <div className="input">
          <input
            onClick={() => {
              navigate("/");
            }}
            type="text"
            onChange={() => {
              "";
            }}
            value={search}
          />
        </div>
      </div>
      <div className="body">
        <div className="content">
          {!loading ? (
            data["message"] !==  "Word Not found!!" ? (
              <div className="main">
                <div className="head">
                  <h2>
                    Word : <span>{search.toLowerCase()}</span>
                  </h2>
                </div>
                {data.data["MEANINGS"] ? (
                  <div className="container">
                    <h3>Meaning's :</h3>
                    <div className="data">
                      {Object.keys(data.data["MEANINGS"]).map((i, ki) => {
                        return (
                          <div key={ki} className="part">
                            {data.data["MEANINGS"][i].map((j, kj) => {
                              return Array.isArray(j) ? (
                                <div key={kj} className="sub-part">
                                  {j.map((k, kk) => (
                                    <p key={kk}>
                                      {k.split(" ").map((l, kl) => (
                                        <span
                                          key={kl}
                                          onClick={() => {
                                            hanndleWord(l);
                                          }}
                                        >
                                          {l + " "}
                                        </span>
                                      ))}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                <p key={kj}>
                                  {j.split(" ").map((l, kl) => (
                                    <span
                                      key={kl}
                                      onClick={() => {
                                        hanndleWord(l);
                                      }}
                                    >
                                      {l + " "}
                                    </span>
                                  ))}
                                </p>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {!isEmpty(data.data["SYNONYMS"]) ? (
                  <div className="container">
                    <h3>Synonyms :</h3>
                    <div className="data">
                      <div className="part">
                        {data.data["SYNONYMS"].map((i, ki) => (
                          <p key={ki}>
                            {i.split(" ").map((j, kj) => (
                              <span
                                key={kj}
                                onClick={() => {
                                  hanndleWord(j);
                                }}
                              >
                                {j + " "}
                              </span>
                            ))}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {!isEmpty(data.data["ANTONYMS"])? (
                  <div className="container">
                    <h3>Antonyms :</h3>
                    <div className="data">
                      <div className="part">
                        {data.data["ANTONYMS"].map((i, ki) => (
                          <p key={ki}>
                            {i.split(" ").map((j, kj) => (
                              <span
                                key={kj}
                                onClick={() => {
                                  hanndleWord(j);
                                }}
                              >
                                {j + " "}
                              </span>
                            ))}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="not-found">
                <div className="main">
                  <div className="head">
                    <h2>Word Not Found!!</h2>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="loading">
              <div className="main">
                <div className="head">
                  <h2>
                    Word : <span>...</span>
                  </h2>
                </div>
                <div className="container">
                  <h3>Meaning's :</h3>
                  <div className="data">
                    <div className="part">
                      <p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <h3>Synonyms :</h3>
                  <div className="data">
                    <div className="part">
                      <p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <h3>Antonyms :</h3>
                  <div className="data">
                    <div className="part">
                      <p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-50">&nbsp;</p><p className="w-100">&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dictionary;
