import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const [search,setSearch] = useState("")
    const navigate = useNavigate()
    const handleSubmit = ()=>{  
      navigate(`/dict/${search.split(" ").join("+")}`)
    }
  return (
    <div className='home'>
        <div className='nav'>
            <h2>Dictionary</h2>
        </div>
        <div className='box'>
            <form onSubmit={handleSubmit}>
            <div className='input'>
            <input value={search} autoFocus onChange={(e)=>{setSearch((e.target.value).trimLeft())}} type='text'></input>
            <label className={`${search?"active-label":""}`}>Search</label>
            </div>
            <div className='button'>
                 <button className={`${search?"active-button":""}`} type='submit'>Search</button>
            </div>
            </form>
        </div>
    </div>
  )
}

export default Home